import { CACHE_TIME } from '@constants/app';
import {
  KEY_FETCH_LOCALE,
  KEY_FETCH_PROFILE,
  KEY_FETCH_PROFILE_AGENCY,
  KEY_FETCH_PROFILE_HOST,
  TYPE_ACCOUNT_AGENCY,
  TYPE_ACCOUNT_HOST,
} from '@constants/profile';
import { KEY_TOKEN, LOCALE_AGENCY, LOCALE_BRAND, LOCALE_HOST, TYPE_BRAND } from '@constants/system';
import { getLocation, getProfile, getProfileAgency, getProfileHost } from '@features/shared/api';
import { IProfileHost } from '@features/shared/model/profile';
import { useQuery } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

export const useAuth = () => {
  const [isAuth, setIsAuth] = useState<boolean>(false);

  useEffect(() => {
    const token = Cookies.get(KEY_TOKEN);
    if (token) {
      setIsAuth(true);
    }
  }, []);

  return isAuth;
};

export const useProfile = () => {
  const typeAccountBrand = Number(Cookies.get(TYPE_BRAND));
  const isLoggedIn = Cookies.get(KEY_TOKEN);

  const profileQuery =
    typeAccountBrand === TYPE_ACCOUNT_HOST
      ? useQuery([KEY_FETCH_PROFILE_HOST], getProfileHost, {
          enabled: typeAccountBrand === TYPE_ACCOUNT_HOST && !!isLoggedIn,
          refetchOnWindowFocus: true,
          cacheTime: CACHE_TIME,
          staleTime: CACHE_TIME,
          retry: 0,
        })
      : typeAccountBrand === TYPE_ACCOUNT_AGENCY
      ? useQuery([KEY_FETCH_PROFILE_AGENCY], getProfileAgency, {
          enabled: typeAccountBrand === TYPE_ACCOUNT_AGENCY && !!isLoggedIn,
          refetchOnWindowFocus: true,
          cacheTime: CACHE_TIME,
          staleTime: CACHE_TIME,
          retry: 0,
        })
      : useQuery([KEY_FETCH_PROFILE], getProfile, {
          enabled: typeAccountBrand !== TYPE_ACCOUNT_HOST && typeAccountBrand !== TYPE_ACCOUNT_AGENCY && !!isLoggedIn,
          refetchOnWindowFocus: true,
          cacheTime: CACHE_TIME,
          staleTime: CACHE_TIME,
          retry: 0,
        });

  const isSuccess = profileQuery.isSuccess;

  const dataReturnConvert = {
    data: {
      data: {
        id: (profileQuery?.data?.data as IProfileHost)?.email,
        email: (profileQuery?.data?.data as IProfileHost)?.email,
        adv_brand_id: '',
        username: profileQuery?.data?.data?.username,
        country: profileQuery?.data?.data?.country,
        currency: profileQuery?.data?.data?.currency,
        locate:
          profileQuery?.data?.data?.country?.toLowerCase() === 'vn'
            ? 'vi'
            : profileQuery?.data?.data?.country?.toLowerCase() || 'en',
        collect_information: true,
        type_brand: (profileQuery?.data?.data as IProfileHost)?.type,
        shop_code: profileQuery?.data?.data?.shop_code,
        shop_connected: profileQuery?.data?.data?.shop_connected,
        is_view_delivery: profileQuery?.data?.data?.is_view_delivery,
        brand_name: profileQuery?.data?.data?.brand_name
      },
    },
  };

  return typeAccountBrand !== TYPE_ACCOUNT_HOST ? { ...profileQuery, isSuccess } : { ...dataReturnConvert, isSuccess };
};

export const useGetLocale = (isRefetch = true) => {
  const locale = useQuery([KEY_FETCH_LOCALE], getLocation, {
    refetchOnWindowFocus: isRefetch,
    cacheTime: CACHE_TIME,
    staleTime: CACHE_TIME,
    retry: 0,
  });

  return locale;
};
export const useGetLocaleStorage = () => {
  const typeAccountBrand = Number(Cookies.get(TYPE_BRAND));
  switch (typeAccountBrand) {
    case TYPE_ACCOUNT_AGENCY:
      return localStorage.getItem(LOCALE_AGENCY);
    case TYPE_ACCOUNT_HOST:
      return localStorage.getItem(LOCALE_HOST);
    default:
      return localStorage.getItem(LOCALE_BRAND);
  }
};
export const useSetLocaleStorage = (locale: string) => {
  const typeAccountBrand = Number(Cookies.get(TYPE_BRAND));
  switch (typeAccountBrand) {
    case TYPE_ACCOUNT_AGENCY:
      return localStorage.setItem(LOCALE_AGENCY, locale);
    case TYPE_ACCOUNT_HOST:
      return localStorage.setItem(LOCALE_HOST, locale);
    default:
      return localStorage.setItem(LOCALE_BRAND, locale);
  }
};
