export const countryCodeToLocale = (code: string) => {
  switch (code) {
    case 'VN':
      return 'vi';
    case 'ID':
      return 'id';
    case 'TH':
      return 'th';
    default:
      return 'en';
  }
};
